


























































































































import Vue, { PropType } from "vue"
import type { WithEvents } from "vue-typed-emit"
import type { WithRefs } from "vue-typed-refs"
import { ValidationObserver } from "vee-validate"
import { cloneDeep } from "lodash"
import invariant from "invariant"
import { v4 } from "uuid"

import { AudioCollection, AudioEntry } from "@/modules/audio/types"
import AudioFactory from "@/modules/audio/entities/AudioFactory"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbSelect,
  RtbCheckbox,
  RtbInputButton,
  RtbInlineHelp
} from "@/components/ui"

import FileUploader from "@/components/FileUploader.vue"

import rules from "@/modules/audio/rules"

export type Refs = {
  observer: InstanceType<typeof ValidationObserver>
}

export interface Events {
  delete: undefined
  save: undefined
  copy: undefined
  cancel: undefined
}

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: "AudioSettings",
  components: {
    ValidationObserver,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbSelect,
    RtbCheckbox,
    RtbInputButton,
    RtbInlineHelp,
    FileUploader
  },
  props: {
    collection: {
      type: String as PropType<AudioCollection>,
      required: true
    },
    value: {
      type: Object as PropType<AudioEntry>
    }
  },
  data() {
    return {
      local: null as AudioEntry,
      loading: false,
      copyCollection: undefined as AudioCollection | undefined,
      copyGameID: undefined as string | undefined,
      copyClientID: undefined as string | undefined,
      AudioCollection
    }
  },
  computed: {
    orgID(): string {
      return this.$store.getters.orgID
    },
    clientID(): string {
      return this.$store.state.auth.clientID
    },
    gameID(): string {
      return this.$store.getters.gameID
    },
    userID(): string {
      return this.$store.getters.user.id
    },
    copyable(): boolean {
      return this.value?.id !== undefined
    },
    removeable(): boolean {
      return this.value?.id !== undefined
    },
    copySelectOptions() {
      return [
        { value: AudioCollection.Client, label: "Session" },
        { value: AudioCollection.Game, label: "Room" },
        { value: AudioCollection.User, label: "User" }
      ]
    },
    overlayable(): boolean {
      return (
        this.collection === AudioCollection.User ||
        this.collection === AudioCollection.Game
      )
    }
  },
  watch: {
    value: {
      handler(value: AudioEntry | null) {
        if (value !== null) {
          this.local = cloneDeep(value)
        }
      },
      immediate: true
    },
    copyCollection(value: AudioCollection | undefined) {
      if (value !== undefined) {
        switch (value) {
          case AudioCollection.Game:
            this.copyGameID = this.$store.getters.gameID
            break
          case AudioCollection.Client:
            this.copyClientID = this.$store.state.auth.clientID
            break
        }
      }
    }
  },
  created() {
    this.rules = rules
  },
  methods: {
    /** @public */
    init() {
      this.local = AudioFactory.create()
    },
    /** @public */
    reset() {
      this.local = null
      this.copyCollection = undefined
      this.loading = false
      this.copyGameID = undefined
      this.copyClientID = undefined
    },
    save() {
      invariant(this.local, "Local audio is not initialized")
      return this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.loading = true
          return this.$services
            .get("audio")
            .then(service => {
              const { id } = this.local
              console.log(this._getOptions())
              if (id === undefined) {
                return service.create(
                  this.collection,
                  this._getOptions(),
                  this.local
                )
              } else {
                service.update(this.collection, this._getOptions(), this.local)
              }
            })
            .finally(() => {
              this.loading = false
              this.$emit("save")
            })
        }
      })
    },
    async copy() {
      invariant(this.local, "Local audio is not initialized")
      invariant(
        this.copyCollection !== undefined,
        "Cannot coppy audio to unspecified collection"
      )
      this.loading = true
      return this.$services
        .get("audio")
        .then(service =>
          service.copy(
            this.copyCollection,
            {
              orgID: this.orgID,
              clientID: this.copyClientID,
              gameID: this.copyGameID,
              userID: this.userID
            },
            this.local
          )
        )
        .finally(() => {
          this.loading = false
          this.$emit("copy")
        })
    },
    remove() {
      invariant(this.value.id, "Audio has no `id`")
      this.loading = true
      return this.$services
        .get("audio")
        .then(service => service.delete(this.collection, this._getOptions()))
        .finally(() => {
          this.loading = false
          this.$emit("delete")
        })
    },
    cancel() {
      this.$emit("cancel")
    },
    _onFile(file: File | null) {
      if (file !== null && this.local?.name === "") {
        this.local.name = file.name.split(".")[0]
      }
    },
    _getOptions() {
      switch (this.collection) {
        case AudioCollection.Client:
          return { clientID: this.clientID, audioID: this.value?.id }
        case AudioCollection.Game:
          return {
            orgID: this.orgID,
            gameID: this.gameID,
            audioID: this.value?.id
          }
        case AudioCollection.User:
          return {
            orgID: this.orgID,
            userID: this.userID,
            audioID: this.value?.id
          }
      }
    },
    _getFileName(file: File) {
      return `audio/${this.clientID}/${v4()}${file.name}`
    }
  }
})
