var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _vm.local
            ? _c(
                "rtb-card-body",
                [
                  _c(
                    "rtb-row",
                    [
                      _c(
                        "rtb-col",
                        { attrs: { xs: "6" } },
                        [
                          _c("rtb-text-input", {
                            attrs: {
                              label: "Name",
                              disabled: _vm.loading,
                              rules: _vm.rules.AudioName,
                            },
                            model: {
                              value: _vm.local.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "name", $$v)
                              },
                              expression: "local.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "rtb-col",
                        { attrs: { xs: "6" } },
                        [
                          _c("file-uploader", {
                            attrs: {
                              label: "Source",
                              accept: [".m4a", ".mp3", ".ogg", ".mpeg"],
                              "file-name": _vm._getFileName,
                              rules: _vm.rules.AudioSource,
                            },
                            on: { file: _vm._onFile },
                            model: {
                              value: _vm.local.source,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "source", $$v)
                              },
                              expression: "local.source",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "rtb-col",
                        { attrs: { xs: "6" } },
                        [
                          _c("rtb-text-input", {
                            attrs: {
                              label: "HEX Color",
                              disabled: _vm.loading,
                            },
                            model: {
                              value: _vm.local.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "color", $$v)
                              },
                              expression: "local.color",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "rtb-col",
                        { attrs: { xs: "6" } },
                        [
                          _c("rtb-text-input", {
                            attrs: {
                              label: "Volume",
                              disabled: _vm.loading,
                              rules: { numeric: true },
                            },
                            model: {
                              value: _vm.local.volume,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "volume", $$v)
                              },
                              expression: "local.volume",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.collection === _vm.AudioCollection.Game ||
                      _vm.collection === _vm.AudioCollection.User
                        ? _c(
                            "rtb-col",
                            { attrs: { xs: "6" } },
                            [
                              _c("rtb-text-input", {
                                attrs: {
                                  label: "Keypress",
                                  disabled: _vm.loading,
                                },
                                model: {
                                  value: _vm.local.keypress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.local, "keypress", $$v)
                                  },
                                  expression: "local.keypress",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.copyable
                        ? _c(
                            "rtb-col",
                            { attrs: { xs: "6" } },
                            [
                              _c("rtb-select", {
                                attrs: {
                                  identity: "value",
                                  label: "Copy To",
                                  options: _vm.copySelectOptions,
                                  disabled: _vm.loading,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.copyCollection
                                      ? {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "rtb-input-button",
                                                {
                                                  attrs: { title: "Remove" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.copyCollection =
                                                        undefined
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      name: "times-regular",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.copyCollection,
                                  callback: function ($$v) {
                                    _vm.copyCollection = $$v
                                  },
                                  expression: "copyCollection",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.copyCollection !== undefined
                        ? _c(
                            "rtb-col",
                            { attrs: { xs: "6" } },
                            [
                              _vm.copyCollection === _vm.AudioCollection.Client
                                ? _c("rtb-text-input", {
                                    attrs: {
                                      label: "Session ID",
                                      rules: { required: true },
                                      disabled: _vm.loading,
                                    },
                                    model: {
                                      value: _vm.copyClientID,
                                      callback: function ($$v) {
                                        _vm.copyClientID = $$v
                                      },
                                      expression: "copyClientID",
                                    },
                                  })
                                : _vm._e(),
                              _vm.copyCollection === _vm.AudioCollection.Game
                                ? _c("rtb-text-input", {
                                    attrs: {
                                      label: "Game ID",
                                      rules: { required: true },
                                      disabled: _vm.loading,
                                    },
                                    model: {
                                      value: _vm.copyGameID,
                                      callback: function ($$v) {
                                        _vm.copyGameID = $$v
                                      },
                                      expression: "copyGameID",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.overlayable
                    ? _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c("rtb-checkbox", {
                            attrs: { label: "Overlay", disabled: _vm.loading },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "help",
                                  fn: function () {
                                    return [
                                      _c("rtb-inline-help", [
                                        _vm._v("Placeholder"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1389206948
                            ),
                            model: {
                              value: _vm.local.overlay,
                              callback: function ($$v) {
                                _vm.$set(_vm.local, "overlay", $$v)
                              },
                              expression: "local.overlay",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "rtb-card-actions",
        [
          _c(
            "rtb-button",
            {
              attrs: { color: "dark", disabled: _vm.loading },
              on: { click: _vm.cancel },
            },
            [_vm._v("Cancel")]
          ),
          _vm.removeable
            ? _c(
                "rtb-button",
                {
                  attrs: { color: "danger", disabled: _vm.loading },
                  on: { click: _vm.remove },
                },
                [_vm._v("Delete")]
              )
            : _vm._e(),
          _vm.copyable && _vm.copyCollection !== undefined
            ? _c(
                "rtb-button",
                {
                  attrs: { color: "secondary", disabled: _vm.loading },
                  on: { click: _vm.copy },
                },
                [
                  _c("svg-icon", { attrs: { name: "copy-regular" } }),
                  _vm._v(" Copy"),
                ],
                1
              )
            : _vm._e(),
          _vm.copyCollection === undefined
            ? _c(
                "rtb-button",
                { attrs: { disabled: _vm.loading }, on: { click: _vm.save } },
                [
                  _c("svg-icon", { attrs: { name: "save-regular" } }),
                  _vm._v(" Save"),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }