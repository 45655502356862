
/**
 * This component is just a transparent wrapper over RtbFileUploader,
 * that passes UplaoderImpl instance to it and aslo do some validation
 */
import Vue, { PropType } from "vue"
import mixins from "vue-typed-mixins"
import { ValidationProvider } from "vee-validate"

import { RtbFileUploader } from "@/components/ui"
import { ValidateableMixin } from "@/components/ui/mixins"

import Uploader from "@/helpers/uploader"

export default mixins(Vue.extend(ValidateableMixin)).extend({
  name: "FileUploader",
  inheritAttrs: false,
  props: {
    value: { type: String },
    label: { type: String, required: true }
  },
  data() {
    return {
      uploader: new Uploader(this.$store.state.auth.token)
    }
  },
  render(h) {
    return h(ValidationProvider, {
      props: {
        rules: this.rules,
        name: this.label
      },
      scopedSlots: {
        default: ({ errors, invalid }) => {
          const [error] = errors

          return h(
            RtbFileUploader,
            {
              props: {
                ...this.$attrs,
                value: this.value,
                uploader: this.uploader,
                label: this.label,
                invalid,
                error
              },
              on: this.$listeners
            },
            this.$slots.default
          )
        }
      }
    })
  }
})
