var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", height: "40" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.create } },
            [_c("v-icon", [_vm._v("add")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "py-1", attrs: { row: "", wrap: "" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-btn",
            {
              key: item.id,
              staticClass: "mx-1",
              style: { backgroundColor: item.color },
              attrs: { dark: "" },
              on: {
                click: [
                  function ($event) {
                    if (!$event.altKey) {
                      return null
                    }
                    return _vm.edit(item.id)
                  },
                  function ($event) {
                    if (!$event.shiftKey) {
                      return null
                    }
                    return _vm.edit(item.id)
                  },
                  function ($event) {
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.select(item.id)
                  },
                ],
              },
            },
            [
              _vm._v(" " + _vm._s(item.name) + " "),
              _c(
                "v-icon",
                {
                  attrs: { right: "" },
                  on: {
                    click: function ($event) {
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.edit(item.id)
                    },
                  },
                },
                [_vm._v("settings")]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }