






























import Vue, { PropType } from "vue"

import { AudioEntry } from "@/modules/audio/types"

export interface Events {
  create: undefined
  edit: string
  select: string
}

export default Vue.extend({
  name: "AudioList",
  props: {
    items: {
      type: Array as PropType<AudioEntry[]>,
      required: true
    },
    title: String
  },
  methods: {
    create() {
      this.$emit("create")
    },
    edit(audioID: string) {
      this.$emit("edit", audioID)
    },
    select(audioID: string) {
      this.$emit("select", audioID)
    }
  }
})
